import { TokenService } from './../../core/services/curd/token.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  token_data: any=[];

  constructor(
    private http: HttpClient,
    private tokens: TokenService
  ) { }

  getToken(credentials,store_in_db=false) {

    const cf_object = {
      "url": `${environment.api_url_old_system}/oauth2/token`,
      "token": 'NA',
      "data": credentials
    };

    return new Promise(resolve => {
      this.http.post(environment.api_handler, cf_object).subscribe(token => {
        const user = {
          token_type: token['token_type'],
          access_token: token['access_token'],
          refresh_token: token['refresh_token'],
        }

        // localStorage.setItem('user', JSON.stringify(user));
        if(store_in_db){
          this.tokens.list().then(data => {
           this.token_data=data;

           let length=this.token_data.length;

           if(length>0){

             const request_object = {
               _id: this.token_data[0]._id,
               input_data: user
             }
             this.tokens.update(request_object).then(res => {

             })
           }else{

             user['synced'] = false;
              this.tokens.create(user).then((res) => {
              });
           }
         });
       }

        resolve(true)

      }, error => {
        console.log(error);
        resolve(false)
      })
    });
  }

  complaints() {
    return [
      {selected: false, complaint: 'Chest Pain', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Angina', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Atypical Chest Pain', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Shortness of Breath', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Palpitation', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Presyncope', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Vertigo', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Ankle Swelling', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'ECG Changes', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Exercise ECG Changes', duration: 'minutes', remarks: '', is_default: true},
      {
        selected: false,
        complaint: 'CT Coronary Angiogram Abnormally',
        duration: 'minutes',
        remarks: '',
        is_default: true
      },
      {
        selected: false,
        complaint: 'Invasive Coronary Angiogram Abnormally',
        duration: 'minutes',
        remarks: '',
        is_default: true
      },
      {selected: false, complaint: 'Post PCI Follow up', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Post CABG Follow up', duration: 'minutes', remarks: '', is_default: true},
      {
        selected: false,
        complaint: 'Pre Op Assessment before Surgery',
        duration: 'minutes',
        remarks: '',
        is_default: true
      },
      {selected: false, complaint: 'Assessment for fit to Work', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Assessment for fit to Drive', duration: 'minutes', remarks: '', is_default: true},
      {selected: false, complaint: 'Medical Checkup', duration: 'minutes', remarks: '', is_default: true},
    ]
  }

  asculation() {
    return [
      {selected: false, name: 'Normal', is_default: true, selections: '-'},
      {selected: false, name: 'Murmus Present', is_default: true, selections: '-'},
      {selected: false, name: 'Systolic Murmur', is_default: true, selections: '-'},
      {selected: false, name: 'Ejection Systolic Murmurs', is_default: true, selections: '-'},
      {selected: false, name: 'Pan Systolic Murmurs', is_default: true, selections: '-'},
      {selected: false, name: 'Early Diastolic Murmurs', is_default: true, selections: '-'},
      {selected: false, name: 'Mid Diastolic Murmurs', is_default: true, selections: '-'},
      {selected: false, name: 'Back of Chest', is_default: true, selections: '-'}];
  }

  lungsFirst() {
    return [
      {selected: false, name: 'Normal', is_default: true, selections: '-'},
      {selected: false, name: 'Rhonchi Present', is_default: true, selections: '-'},
      {selected: false, name: 'Crepitation Present', is_default: true, selections: '-'},
      {selected: false, name: 'Bronchial Breathing Present', is_default: true, selections: '-'},
      {selected: false, name: 'Muffled Lung Sounds', is_default: true, selections: '-'},
    ];
  }

  lungsSecond() {
    return [
      {selected: false, name: 'Right Base', is_default: true},
      {selected: false, name: 'Left Base', is_default: true},
      {selected: false, name: 'Right Atrial', is_default: true},
      {selected: false, name: 'Left Atrial', is_default: true},
      {selected: false, name: 'Right Mid Zone', is_default: true},
      {selected: false, name: 'Left Mid Zone', is_default: true},
      {selected: false, name: 'Bibasal', is_default: true},
      {selected: false, name: 'Biapical', is_default: true},
    ];
  }

  CardiacRiskFactor(data) {

    if (!data.diabetest2) {
      data.diabetest2 = false;
    }
    if (!data.diabetest1) {
      data.diabetest1 = false;
    }
    if (!data.hypertension) {
      data.hypertension = false;
    }
    if (!data.ischemic) {
      data.ischemic = false;
    }
    if (!data.peripheralVascularDisease) {
      data.peripheralVascularDisease = false;
    }
    return [
      {
        selected: data.diabetest1,
        name: 'T1 Diabetes Mellitus',
        date: data.diabetest1Date,
        remarks: data.diabetestRemarks,
        is_default: true
      },
      {
        selected: data.diabetest2,
        name: 'T2 Diabetes Mellitus',
        date: data.diabetest2Date,
        remarks: data.diabetest2Remarks,
        is_default: true
      },
      {
        selected: data.hypertension,
        name: 'Hypertension',
        date: data.hypertensionDate,
        remarks: data.hypertensionRemarks,
        is_default: true
      },
      {selected: false, name: 'Hyperlipidemia', date: '', remarks: '', is_default: true},
      {selected: false, name: 'Hypertriglyceridemia', date: '', remarks: '', is_default: true},
      {
        selected: data.ischemic,
        name: 'Ischemic Heart Disease',
        date: data.ischemicDate,
        remarks: data.ischemicRemarks,
        is_default: true
      },
      {selected: false, name: 'Stroke', date: '', remarks: '', is_default: true},
      {
        selected: data.peripheralVascularDisease,
        name: 'Peripheral Vascular Disease',
        date: data.peripheralVascularDiseaseDate,
        remarks: data.peripheralVascularRemarks,
        is_default: true
      },
    ];
  }

  otherMedicalConditions(data) {

    if (!data.bronchialAsthma) {
      data.bronchialAsthma = false;
    }
    if (!data.chronicKidneyDisease) {
      data.chronicKidneyDisease = false;
    }
    if (!data.GORD) {
      data.GORD = false;
    }

    const arr = [
      {selected: false, name: 'Anemia', date: '', remarks: '', is_default: true},
      {selected: false, name: 'Hyperthyroidism', date: '', remarks: '', is_default: true},
      {
        selected: data.bronchialAsthma,
        name: 'Bronchial Asthma',
        date: data.bronchialAsthmaDate,
        remarks: data.bronchialAsthmaRemarks,
        is_default: true
      }, //has
      {
        selected: data.chronicKidneyDisease,
        name: 'Chronic Kidney Disease',
        date: data.chronicKidneyDiseaseDate,
        remarks: data.chronicKidneyDiseaseRemarks,
        is_default: true
      }, //has
      {selected: false, name: 'Bleeding Disorders', date: '', remarks: '', is_default: true},
      {selected: false, name: 'Arthritis', date: '', remarks: '', is_default: true},
      {selected: false, name: 'Chronic Liver Cell Disease', date: '', remarks: '', is_default: true},
      {selected: false, name: 'Fatty Liver', date: '', remarks: '', is_default: true},
      {selected: false, name: 'Gastric Ulcers', date: '', remarks: '', is_default: true},
      {selected: data.GORD, name: 'G O R D', date: data.GORDDate, remarks: data.GORDRemarks, is_default: true}, //has
    ];

    if (data.stableAngina) {
      arr.push({
        selected: data.stableAngina,
        name: 'Stable angina',
        date: data.stableAnginaDate,
        remarks: data.stableAnginaRemarks,
        is_default: true
      })
    }
    if (data.unstableAngina) {
      arr.push({
        selected: data.unstableAngina,
        name: 'Unstable angina',
        date: data.unstableAnginaDate,
        remarks: data.unstableAnginaRemarks,
        is_default: true
      })
    }
    if (data.MyocardialInfarctionNSTEMI) {
      arr.push({
        selected: data.MyocardialInfarctionNSTEMI,
        name: 'Myocardial infarction (NSTEMI)',
        date: data.MyocardialInfarctionNSTEMIDate,
        remarks: data.MyocardialInfarctionNSTEMIRemarks,
        is_default: true
      })
    }
    if (data.MyocardialInfarctionSTEMI) {
      arr.push({
        selected: data.MyocardialInfarctionSTEMI,
        name: 'Myocardial infarction (STEMI)',
        date: data.MyocardialInfarctionSTEMIDate,
        remarks: data.MyocardialInfarctionSTEMIRemarks,
        is_default: true
      })
    }
    if (data.otherHID) {
      arr.push({
        selected: data.otherHID,
        name: 'Other',
        date: data.otherHIDDate,
        remarks: data.otherHIDRemarks,
        is_default: true
      })
    }
    if (data.AtrialFibrillation) {
      arr.push({
        selected: data.AtrialFibrillation,
        name: 'Atrial fibrillation',
        date: data.AtrialFibrillationDate,
        remarks: data.AtrialFibrillationRemarks,
        is_default: true
      })
    }
    if (data.dyslipidemia) {
      arr.push({
        selected: data.dyslipidemia,
        name: 'Dyslipidemia',
        date: data.dyslipidemiaDate,
        remarks: data.dyslipidemiaRemarks,
        is_default: true
      })
    }
    if (data.hemorrhagic) {
      arr.push({
        selected: data.hemorrhagic,
        name: 'Hemorrhagic',
        date: data.hemorrhagicDate,
        remarks: data.hemorrhagicRemarks,
        is_default: true
      })
    }
    if (data.COPD) {
      arr.push({
        selected: data.COPD,
        name: 'C O P D',
        date: data.COPDDate,
        remarks: data.COPDRemarks,
        is_default: true
      })
    }
    if (data.asthma) {
      arr.push({
        selected: data.asthma,
        name: 'Asthma',
        date: data.asthmaDate,
        remarks: data.asthmaRemarks,
        is_default: true
      })
    }
    if (data.bleedingDiabetes) {
      arr.push({
        selected: data.bleedingDiabetes,
        name: 'Bleeding Diabetes',
        date: data.bleedingDiabetesDate,
        remarks: data.bleedingDiabetesRemarks,
        is_default: true
      })
    }

    return arr;
  }

  cardiacInvestigations() {
    return [
      {selected: false, name: 'ECG', remarks: '', is_default: true},
      {selected: false, name: 'Exercise ECG', remarks: '', is_default: true},
      {selected: false, name: '24 hour holter monitoring (12 lead)', remarks: '', is_default: true},
      {selected: false, name: '24 hour ambulatory BP monitoring', remarks: '', is_default: true},
      {selected: false, name: '6min walk saturation monitoring', remarks: '', is_default: true},
      {
        selected: false,
        name: 'Lung function test with airway reversibility assessment',
        remarks: '',
        is_default: true
      },
      {selected: false, name: 'Cardiopulmonary Function Test', remarks: '', is_default: true},
      {selected: false, name: '2D echocardiogram', remarks: '', is_default: true},
      {selected: false, name: '3D echocardiogram and advance echo', remarks: '', is_default: true},
      {selected: false, name: 'Trans oesophageal echo(TOE)', remarks: '', is_default: true},
      {selected: false, name: 'Stress echocardiogram', remarks: '', is_default: true},
      {selected: false, name: 'Dobutamine stress echo cardiogram (DSE)', remarks: '', is_default: true},
      {selected: false, name: 'Nuclear perfusion scan (SPECT)', remarks: '', is_default: true},
      {selected: false, name: 'Cardiac CT coronary angiogram(CTCA)', remarks: '', is_default: true},
      {selected: false, name: 'Calcium score', remarks: '', is_default: true},
      {selected: false, name: 'Invasive coronary angiogram', remarks: '', is_default: true},
      {selected: false, name: 'Cardiac MRI', remarks: '', is_default: true},
    ]
  }

  radiological_investigations() {
    return [
      {selected: false, name: 'Chest X-Ray', value: '', uom: '', remarks: '', is_default: true},
      {selected: false, name: 'CT Chest', value: '', uom: '', remarks: '', is_default: true},
      {selected: false, name: 'CT Abdomen', value: '', uom: '', remarks: '', is_default: true},
      {selected: false, name: 'CT Brain', value: '', uom: '', remarks: '', is_default: true},
      {selected: false, name: 'MRI Brain', value: '', uom: '', remarks: '', is_default: true},
      {selected: false, name: 'US Scan of Abdomen', value: '', uom: '', remarks: '', is_default: true},
      {selected: false, name: 'Mammogram', value: '', uom: '', remarks: '', is_default: true},
    ]
  }

  biochemical_investigations(data) {

    let fbs = false;
    if (data.fbsBio) {
      fbs = true;
    }
    let hb = false;
    if (data.hb) {
      hb = true;
    }
    let wbc = false;
    if (data.wbc) {
      wbc = true;
    }
    let platelets = false;
    if (data.platelets) {
      platelets = true;
    }
    let ppbsBio = false;
    if (data.ppbsBio) {
      ppbsBio = true;
    }
    let hbaic = false;
    if (data.hbaic) {
      hbaic = true;
    }
    let totalChol = false;
    if (data.totalChol) {
      totalChol = true;
    }
    let ldl = false;
    if (data.ldl) {
      ldl = true;
    }
    let hdl = false;
    if (data.hdl) {
      hdl = true;
    }
    let tri = false;
    if (data.tri) {
      tri = true;
    }
    let sgpt = false;
    if (data.sgpt) {
      sgpt = true;
    }
    let sCreat = false;
    if (data.sCreat) {
      sCreat = true;
    }
    let naPlus = false;
    if (data.naPlus) {
      naPlus = true;
    }
    let tshBio = false;
    if (data.tshBio) {
      tshBio = true;
    }
    let kPlus = false;
    if (data.kPlus) {
      kPlus = true;
    }
    let inr = false;
    if (data.inr) {
      inr = true;
    }
    let cpk = false;
    if (data.cpk) {
      cpk = true;
    }

    return [
      {selected: fbs, name: 'FBS', value: data.fbsBio, uom: 'mg/dl', remarks: data.fbsBioRemarks, is_default: true},
      {
        selected: ppbsBio,
        name: 'PPBS',
        value: data.ppbsBio,
        uom: 'mg/dl',
        remarks: data.ppbsBioRemarks,
        is_default: true
      },
      {selected: hbaic, name: 'HBAIC', value: data.hbaic, uom: '%', remarks: data.hbaicRemarks, is_default: true},
      {
        selected: totalChol,
        name: 'Cholesterol',
        value: data.totalChol,
        uom: 'mg/dl',
        remarks: data.totalCholRemarks,
        is_default: true
      },
      {selected: ldl, name: 'IDL', value: data.ldl, uom: 'mg/dl', remarks: data.ldlRemarks, is_default: true},
      {selected: hdl, name: 'HDL', value: data.hdl, uom: 'mg/dl', remarks: data.hdlRemarks, is_default: true},
      {selected: tri, name: 'TRI', value: data.tri, uom: 'mg/dl', remarks: data.triRemarks, is_default: true},
      {selected: hb, name: 'HB', value: data.hb, uom: 'g/dl', remarks: data.hbRemarks, is_default: true},
      {selected: wbc, name: 'WBC', value: data.wbc, uom: '10^9/1', remarks: data.wbcRemarks, is_default: true},
      {
        selected: platelets,
        name: 'PLATELETS',
        value: data.platelets,
        uom: '10^9/1',
        remarks: data.plateletsRemarks,
        is_default: true
      },
      {selected: sgpt, name: 'SGPT', value: data.sgpt, uom: 'mg/dl', remarks: data.sgptRemarks, is_default: true},
      {
        selected: sCreat,
        name: 'SCREAT',
        value: data.sCreat,
        uom: 'mg/dl',
        remarks: data.sCreatRemarks,
        is_default: true
      },
      {
        selected: naPlus,
        name: 'NA+',
        value: data.naPlus,
        uom: 'mg/dl',
        remarks: data.naPlusRemarks,
        is_default: true
      },
      {selected: tshBio, name: 'TSH', value: data.tshBio, uom: 'u/l', remarks: data.tshBioRemarks, is_default: true},
      {selected: kPlus, name: 'K+', value: data.kPlus, uom: 'mg/dl', remarks: data.kPlusRemarks, is_default: true},
      {selected: inr, name: 'INR', value: data.inr, uom: '%', remarks: data.inrRemarks, is_default: true},
      {selected: cpk, name: 'CPK', value: data.cpk, uom: '%', remarks: data.cpkRemarks, is_default: true},
    ]
  }

  pastPci(data) {
    return [
      {
        selected: data.lmsStatus,
        name: 'LMS',
        dms: data.lmsType,
        date: data.lmsDate,
        remarks: data.lmsRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: data.ladStatus,
        name: 'LAD',
        dms: data.ladType,
        date: data.ladDate,
        remarks: data.ladRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: data.lcxStatus,
        name: 'LCX',
        dms: data.lcxType,
        date: data.lcxDate,
        remarks: data.lcxRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: data.om1Status,
        name: 'OM1',
        dms: data.om1Type,
        date: data.om1Date,
        remarks: data.om1Remarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: data.om2Status,
        name: 'OM2',
        dms: data.om2Type,
        date: data.om2Date,
        remarks: data.om2Remarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: data.om3Status,
        name: 'OM3',
        dms: data.om3Type,
        date: data.om3Date,
        remarks: data.om3Remarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: data.om4Status,
        name: 'OM4',
        dms: data.om4Type,
        date: data.om4Date,
        remarks: data.om4Remarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: data.rcaStatus,
        name: 'RCA',
        dms: data.rcaType,
        date: data.rcaDate,
        remarks: data.rcaRemarks,
        selected_two: false,
        is_default: true
      },
      {selected: false, name: 'RDA', dms: '', date: '', remarks: '', selected_two: false, is_default: true},
      {
        selected: data.pdaStatus,
        name: '',
        dms: data.pdaType,
        date: data.pdaDate,
        remarks: data.pdaRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: data.pciRiStatus,
        name: 'PIC',
        dms: data.pciRiType,
        date: data.pciRiDate,
        remarks: data.pciRiRemarks,
        selected_two: false,
        is_default: true
      },
    ];
  }

  pastCabg(data) {
    let lad = false;
    if (data.ladLima || data.ladRima || data.ladRa || data.ladSvg) {
      lad = true;
    }

    let di = false;
    if (data.diLima || data.diRima || data.diRa || data.diSvg) {
      di = true;
    }

    let om1 = false;
    if (data.om1Lima || data.om1Rima || data.om1Ra || data.om1Svg) {
      om1 = true;
    }

    let om2 = false;
    if (data.om2Lima || data.om2Rima || data.om2Ra || data.om2Svg) {
      om2 = true;
    }

    let lpda = false;
    if (data.lpdaLima || data.lpdaRima || data.lpdaRa || data.lpdaSvg) {
      lpda = true;
    }

    let fca = false;
    if (data.fcaLima || data.fcaRima || data.fcaRa || data.fcaSvg) {
      fca = true;
    }

    let sti = false;
    if (data.stiLima || data.stiRima || data.stiRa || data.stiSvg) {
      sti = true;
    }

    let plv = false;
    if (data.plvLima || data.plvRima || data.plvRa || data.plvSvg) {
      plv = true;
    }


    let rpda = false;
    if (data.rpdaLima || data.rpdaRima || data.rpdaRa || data.rpdaSvg) {
      rpda = true;
    }

    return [
      {
        selected: lad,
        name: 'LAD',
        LIMA: data.ladLima,
        RIMA: data.ladRima,
        RA: data.ladRa,
        SVG: data.ladSvg,
        remarks: data.ladCabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: di,
        name: 'DI',
        LIMA: data.diLima,
        RIMA: data.diRima,
        RA: data.diRa,
        SVG: data.diSvg,
        remarks: data.diCabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: om1,
        name: 'OM1',
        LIMA: data.om1Lima,
        RIMA: data.om1Rima,
        RA: data.om1Ra,
        SVG: data.om1Svg,
        remarks: data.om1CabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: om2,
        name: 'OM2',
        LIMA: data.om2Lima,
        RIMA: data.om2Rima,
        RA: data.om2Ra,
        SVG: data.om2Svg,
        remarks: data.om2CabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: lpda,
        name: 'LPDA',
        LIMA: data.lpdaLima,
        RIMA: data.lpdaRima,
        RA: data.lpdaRa,
        SVG: data.lpdaSvg,
        remarks: data.lpdaCabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: fca,
        name: 'FCA',
        LIMA: data.fcaLima,
        RIMA: data.fcaRima,
        RA: data.fcaRa,
        SVG: data.fcaSvg,
        remarks: data.fcaCabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: sti,
        name: 'STI',
        LIMA: data.stiLima,
        RIMA: data.stiRima,
        RA: data.stiRa,
        SVG: data.stiSvg,
        remarks: data.stiCabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: rpda,
        name: 'RPDA',
        LIMA: data.rpdaLima,
        RIMA: data.rpdaRima,
        RA: data.rpdaRa,
        SVG: data.rpdaSvg,
        remarks: data.rpdaCabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: plv,
        name: 'PLV',
        LIMA: data.plvLima,
        RIMA: data.plvRima,
        RA: data.plvRa,
        SVG: data.plvSvg,
        remarks: data.plvCabgRemarks,
        selected_two: false,
        is_default: true
      },
      {
        selected: false,
        name: 'CABG',
        LIMA: data.cabgRiLima,
        RIMA: data.cabgRiRima,
        RA: data.cabgRiRa,
        SVG: data.cabgRiSvg,
        remarks: data.cabgRiRemarks,
        selected_two: false,
        is_default: true
      },
    ];
  }

  past_surgeries(data) {
    return [
      {
        selected: data.aorticValStatus,
        name: 'Aortic valvotomy',
        date: data.aorticValDate,
        remarks: data.aorticValRemarks,
        is_default: true
      },
      {
        selected: data.avTissueStatus,
        name: 'AV Replacement - Tissue valve',
        date: data.avTissueDate,
        remarks: data.avTissueRemarks,
        is_default: true
      },
      {
        selected: data.avMechanicalStatus,
        name: 'AV Replacement - Mechanical valve',
        date: data.avMechanicalDate,
        remarks: data.avMechanicalRemarks,
        is_default: true
      },
      {
        selected: data.aorticRootStatus,
        name: 'Aortic root replacement',
        date: data.aorticRootDate,
        remarks: data.aorticRootRemarks,
        is_default: true
      },
      {
        selected: data.tvTissueStatus,
        name: 'TV replacement - Tissue valve',
        date: data.tvTissueDate,
        remarks: data.tvTissueRemarks,
        is_default: true
      },
      {
        selected: data.tvMechanicalStatus,
        name: 'TV replacement - Mechanical Valve',
        date: '',
        remarks: '',
        is_default: true
      },
      {
        selected: false,
        name: 'Pulmonary Valvotomy',
        date: data.tvMechanicalDate,
        remarks: data.tvMechanicalRemarks,
        is_default: true
      },
      {
        selected: data.pvReplacementStatus,
        name: 'PV replacement',
        date: data.pvReplacementDate,
        remarks: data.pvReplacementRemarks,
        is_default: true
      },
      {
        selected: data.cardiacSurgeriesStatus,
        name: 'Other cardiac surgeries',
        date: data.cardiacSurgeriesDate,
        remarks: data.cardiacSurgeriesRemarks,
        is_default: true
      },
      {
        selected: data.tenValStatus,
        name: 'Mitral Valvotomy',
        date: data.tenValDate,
        remarks: data.tenValRemarks,
        is_default: true
      },
      {
        selected: data.elevenValStatus,
        name: 'MV repair',
        date: data.elevenValDate,
        remarks: data.elevenValRemarks,
        is_default: true
      },
      {
        selected: data.twelveValStatus,
        name: 'MV replacement - Mechanical - Value',
        date: data.twelveValDate,
        remarks: data.twelveValRemarks,
        is_default: true
      },
      {
        selected: data.fourteenValStatus,
        name: 'MV annuloplasty',
        date: data.fourteenValDate,
        remarks: data.fourteenValRemarks,
        is_default: true
      },
      {
        selected: data.fifteenValStatus,
        name: 'PTMC',
        date: data.fifteenValDate,
        remarks: data.fifteenValRemarks,
        is_default: true
      },
      {
        selected: data.sixteenValStatus,
        name: 'Mitral Clip',
        date: data.sixteenValDate,
        remarks: data.sixteenValRemarks,
        is_default: true
      },
    ];
  }

  past_structures() {
    return [
      {selected: false, name: 'ASD Closure', date: '', remarks: '', is_default: true},
      {selected: false, name: 'USD Closure', date: '', remarks: '', is_default: true},
      {selected: false, name: 'PFO Closure', date: '', remarks: '', is_default: true},
      {selected: false, name: 'TAVI', date: '', remarks: '', is_default: true},
      {selected: false, name: 'Stenting to Coarctation of Aorta', date: '', remarks: '', is_default: true},
      {selected: false, name: 'PTMC', date: '', remarks: '', is_default: true},
      {selected: false, name: 'Mitral Clip', date: '', remarks: '', is_default: true},
      {selected: false, name: 'ICD Implantation', date: '', remarks: '', is_default: true},
      {selected: false, name: 'PPM Implantation', date: '', remarks: '', is_default: true},
      {selected: false, name: 'CRT-D Implantation', date: '', remarks: '', is_default: true},
      {selected: false, name: 'CRT-P Implantation', date: '', remarks: '', is_default: true},
    ];
  }

  medications(current_medications) {
    const arr = [];
    for (let med of current_medications) {
      let medicine = med.type.name;
      let brand = med.medicine.name;
      let dosage = med.quantity.name;
      let frequency = med.how.name;
      let duration = med.howMany.name;
      let extra = med.extra;
      let selected = true;

      arr.push({
        medicine: medicine,
        brand: brand,
        dosage: dosage,
        frequency: frequency,
        duration: duration,
        extra: extra,
        selected: selected
      })
    }
    return arr;
  }

  cardiacInvestToDone(data) {
    return [
      {selected: data.ecg, name: 'ECG', remarks: data.ecgRemarks, is_default: true},
      {selected: data.exerciseEcg, name: 'Exercise ECG', remarks: data.exerciseEcgRemarks, is_default: true},
      {
        selected: data.holterMonitoring,
        name: '24 hour holter monitoring (12 lead)',
        remarks: data.holterMonitoringRemarks,
        is_default: true
      },
      {
        selected: data.ambulatoryBpMonitoing,
        name: '24 hour ambulatory BP monitoring',
        remarks: data.ambulatoryBpMonitoingRemarks,
        is_default: true
      },
      {
        selected: data.saturationMonitoring,
        name: '6min walk saturation monitoring',
        remarks: data.saturationMonitoringRemarks,
        is_default: true
      },
      {
        selected: data.reversibilityAssessment,
        name: 'Lung function test with airway reversibility assessment',
        remarks: data.reversibilityAssessmentRemarks,
        is_default: true
      },
      {
        selected: data.cardiopulmonaryTest,
        name: 'Cardiopulmonary Function Test',
        remarks: data.cardiopulmonaryTestRemarks,
        is_default: true
      },
      {selected: data.twoDEcho, name: '2D echocardiogram', remarks: data.twoDEchoRemarks, is_default: true},
      {
        selected: data.threeDEcho,
        name: '3D echocardiogram and advance echo',
        remarks: data.threeDEchoRemarks,
        is_default: true
      },
      {
        selected: data.transEcho,
        name: 'Trans oesophageal echo(TOE)',
        remarks: data.transEchoRemarks,
        is_default: true
      },
      {selected: data.stressEcho, name: 'Stress echocardiogram', remarks: data.stressEchoRemarks, is_default: true},
      {
        selected: data.dse,
        name: 'Dobutamine stress echo cardiogram (DSE)',
        remarks: data.dseRemarks,
        is_default: true
      },
      {selected: data.spect, name: 'Nuclear perfusion scan (SPECT)', remarks: data.spectRemarks, is_default: true},
      {selected: data.ctca, name: 'Cardiac CT coronary angiogram(CTCA)', remarks: data.ctcaRemarks, is_default: true},
      {selected: data.calciumScore, name: 'Calcium score', remarks: data.calciumScoreRemarks, is_default: true},
      {selected: data.ica, name: 'Invasive coronary angiogram', remarks: data.icaRemarks, is_default: true},
      {selected: data.cardiacMri, name: 'Cardiac MRI', remarks: data.cardiacMriRemarks, is_default: true},
    ];
  }

  bio_investigations_to_done(data) {
    return [
      {selected: data.alt, name: 'ALT', remarks: data.alttRemarks, is_default: true},
      {selected: data.ast, name: 'AST', remarks: data.astRemarks, is_default: true},
      {selected: data.liverTest, name: 'Liver function test', remarks: data.liverTestRemarks, is_default: true},
      {selected: data.renalProfile, name: 'Rental Profile', remarks: data.renalProfileRemarks, is_default: true},
      {selected: data.serumCalcium, name: 'Serum calcium', remarks: data.serumCalciumRemarks, is_default: true},
      {selected: data.serumCkMb, name: 'Serum CK-MB', remarks: data.serumCkMbRemarks, is_default: true},
      {selected: data.egfr, name: 'Serum creatinine with EGFR', remarks: data.egfrRemarks, is_default: true},
      {
        selected: data.serumElecrolyte,
        name: 'Serum electrolytes',
        remarks: data.serumElecrolyteRemarks,
        is_default: true
      },
      {selected: data.ldh, name: 'Serum lactate dehydrogenase(LDH)', remarks: data.ldhRemarks, is_default: true},
    ]
  }

  radiological_investigations_to_done(data) {
    return [
      {selected: data.serumMagnesium, name: 'Serum magnesium', remarks: data.serumMagnesiumRemarks, is_default: true},
      {selected: data.thyroid, name: 'Thyroid Profile', remarks: data.thyroidRemarks, is_default: true},
      {selected: data.troponinT, name: 'Troponin T', remarks: data.troponinTRemarks, is_default: true},
      {selected: data.troponinI, name: 'Troponin I', remarks: data.troponinIRemarks, is_default: true},
      {selected: data.tsh, name: 'TSH', remarks: data.tshRemarks, is_default: true},
      {selected: data.ufr, name: 'UFR', remarks: data.ufrRemarks, is_default: true},
      {
        selected: data.scanOfAbdomen,
        name: 'US scan of abdomen',
        remarks: data.scanOfAbdomenRemarks,
        is_default: true
      },
      {
        selected: data.urineMetanephrine,
        name: '24 hour urine metanephrine',
        remarks: data.urineMetanephrineRemarks,
        is_default: true
      },
      {selected: data.bloodPicture, name: 'Blood picture', remarks: data.bloodPictureRemarks, is_default: true},
      {selected: data.chestXRay, name: 'Chest X-ray-PA', remarks: data.chestXRayRemarks, is_default: true},
      {
        selected: data.creatinePhosphokinse,
        name: 'Creatine phosphokinase',
        remarks: data.creatinePhosphokinseRemarks,
        is_default: true
      },
      {selected: data.esr, name: 'ESR', remarks: data.esrRemarks, is_default: true},
      {selected: data.crp, name: 'CRP', remarks: data.crpRemarks, is_default: true},
      {selected: data.fbs, name: 'FasTing blood sugar(FBS)', remarks: data.fbsRemarks, is_default: true},
      {selected: data.fbc, name: 'Full blood count(FBC)', remarks: data.fbcRemarks, is_default: true},
      {selected: data.hb1Ac, name: 'Hb 1Ac', remarks: data.hb1AcRemarks, is_default: true},
      {
        selected: data.hbElectrophoresis,
        name: 'Hb electrophoresis',
        remarks: data.hbElectrophoresisRemarks,
        is_default: true
      },
      {selected: data.lipidProfile, name: 'Lipid profile', remarks: data.lipidProfileRemarks, is_default: true},
      {selected: data.ppbs, name: 'PPBS', remarks: data.ppbsRemarks, is_default: true},
      {selected: data.protein, name: 'Protein electrophoresis', remarks: data.proteinRemarks, is_default: true},
      {selected: data.ptt, name: 'PTT/INR', remarks: data.pttRemarks, is_default: true},
      {selected: data.renin, name: 'Renin /Aldosterone ratio', remarks: data.reninRemarks, is_default: true},
      {selected: data.serumCortisol, name: 'Serum Cortisol', remarks: data.serumCortisolRemarks, is_default: true},
      {
        selected: data.serumMetanephrine,
        name: 'Serum metanephrine',
        remarks: data.serumMetanephrineRemarks,
        is_default: true
      },
      {selected: data.xRay, name: 'X-Ray', remarks: data.xRayRemarks, is_default: true},
    ];
  }

  plan_of_management() {
    return [
      {selected: false, name: 'Review in 2 weeks', is_default: true},
      {selected: false, name: 'Review in 1 month', is_default: true},
      {selected: false, name: 'Review in 6 month', is_default: true},
      {selected: false, name: 'Planned for Coronary angiogram', is_default: true},
      {selected: false, name: 'Planned for PCI', is_default: true},
      {selected: false, name: 'Planned for Structural intervention', is_default: true},
      {selected: false, name: 'Referred for CABG', is_default: true},
      {selected: false, name: 'Planned for further imaging TOE', is_default: true},
      {selected: false, name: 'Planned for further imaging DSE', is_default: true},
      {selected: false, name: 'Planned for further imaging CTCA', is_default: true},
      {selected: false, name: 'Planned for further imaging CMR', is_default: true},
      {selected: false, name: 'Planned for further imaging Nuclear scan', is_default: true},
    ];
  }
  refreshToken(refresh_token) {
    console.log('refresh token running');
    return new Promise((resolve, reject) => {
      const cf_object = {
        "url": `${environment.api_url_old_system}/oauth2/token`,
        "token": 'NA',
        "data": {
          "grant_type": "refresh_token",
          "refresh_token": refresh_token
        }
      };
      console.log(cf_object);
      this.http.post(environment.api_handler, cf_object).subscribe(token => {

        const user = {
          token_type: token['token_type'],
          access_token: token['access_token'],
          refresh_token: token['refresh_token'],
        }

        this.tokens.list().then(data => {

          this.token_data = data;
          let length = this.token_data.length;

          if (length > 0) {
            const request_object = {
              _id: this.token_data[0]._id,
              input_data: user
            }
            console.log(request_object);
            this.tokens.updateWithoutToast(request_object).then(res => {
              resolve(token['access_token']);
            });
          }
        });
      });
    })
  }

  // refreshToken(refresh_token) {
  //   console.log('refresh token running')

  //   const cf_object = {
  //     "url": `${environment.api_url2}/oauth2/token`,
  //     "token": 'NA',
  //     "data": {
  //       "grant_type": "refresh_token",
  //       "refresh_token": refresh_token
  //     }
  //   };

  //   return new Promise(resolve => {
  //     this.http.post(environment.api_handler, cf_object).subscribe(token => {

  //       const user = {
  //         token_type: token['token_type'],
  //         access_token: token['access_token'],
  //         refresh_token: token['refresh_token'],
  //       }

  //       this.tokens.list().then(data => {
  //         console.log(data)
  //         this.token_data = data;
  //         let length = this.token_data.length;
  //         if (length > 0) {
  //           const request_object = {
  //             _id: this.token_data[0]._id,
  //             input_data: user
  //           }
  //           this.tokens.updateWithoutToast(request_object).then(res => {
  //             resolve(token['access_token']);
  //           });
  //         }
  //       });

  //     }, error => {
  //       console.log(error);
  //       resolve(false)
  //     })
  //   });

  // }
}
