import { Injectable } from '@angular/core';
import { ToastService } from '../misc/toast.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  constructor(private http: HttpClient, private toast: ToastService) { }

  create(request_body) {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.api_url}/appointments/create`, request_body)
        .pipe(
          this.toast.observe(
            'Record Saving..',
            'Successfully Saved',
            'Something Went Wrong'
          )
        )
        .subscribe((res) => {
          resolve(res);
        }, error => {
          console.log(error);
        });
    });
  }

  update(request_body) {


    return new Promise((resolve) => {
      this.http
        .put(`${environment.api_url}/appointments/update`, request_body)
        .pipe(
          this.toast.observe(
            'Record Updating..',
            'Successfully Updated',
            'Something Went Wrong'
          )
        )
        .subscribe((res) => {
          // @ts-ignore
          resolve(res);
        });
    });
  }

  updateWithoutToast(request_body) {
    return new Promise((resolve) => {
      this.http
        .put(`${environment.api_url}/appointments/update`, request_body)
        .subscribe((res) => {
          // @ts-ignore
          resolve(res);
        });
    });
  }

  updateStatus(request_body) {
    return new Promise((resolve) => {
      this.http
        .put(`${environment.api_url}/appointments/update/appoinment/status`, request_body)
        .subscribe((res) => {
          // @ts-ignore
          resolve(res);
        });
    });
  }

  createWithOutToast(request_body) {
    return new Promise(resolve => {
      this.http.post(`${environment.api_url}/appointments/create`, request_body).pipe(
      ).subscribe(res => {
        resolve(res);
      });
    });
  }
  updateQuery(request_body) {
    return new Promise((resolve) => {
      this.http
        .put(`${environment.api_url}/appointments/update/query`, request_body)
        .subscribe((res) => {
          // @ts-ignore
          resolve(res);
        });
    });
  }

  list() {
    return new Promise((resolve) => {
      this.http.get(`${environment.api_url}/appointments`).subscribe((res) => {
        resolve(res);
      });
    });
  }

  find(request_body) {
    return new Promise((resolve) => {
      this.http.post(`${environment.api_url}/appointments/find`, request_body).subscribe((res) => {
        resolve(res);
      });
    });
  }

  delete(request_body) {
    return new Promise((resolve) => {
      this.http
        .delete(`${environment.api_url}/appointments/delete/${request_body}`)
        .pipe(
          this.toast.observe(
            'Record Deleting..',
            'Successfully Deleted',
            'Something Went Wrong'
          )
        )
        .subscribe((res) => {
          // @ts-ignore
          resolve(res.data);
        });
    });
  }

  deleteBulk(request_body) {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.api_url}/appointments/delete/query`, request_body)
        .subscribe((res) => {
          // @ts-ignore
          resolve(res.data);
        });
    });
  }

  show(request_body) {
    //method changed
    return new Promise((resolve) => {
      this.http
        .get(`${environment.api_url}/appointments/show/${request_body}`)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  history(patient_id) {
    return new Promise((resolve) => {
      this.http
        .get(`${environment.api_url}/appointments/history/${patient_id}`)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  changeBookingStatus(status, document_id) {
    this.show(document_id).then(booking => {
      if (booking['status'] != 'printed') {
        const request_object = {
          _id: document_id,
          input_data: { status: status },
        };
        this.updateWithoutToast(request_object);
      }
    })
  }
}
