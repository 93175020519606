import { Injectable } from '@angular/core';
import {ElectronService} from "../electron/electron.service";
import {ToastService} from "./toast.service";
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor(
    private http : HttpClient
  ) { }

  getBookings(date){
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api_url}/sidebar/get-booking-by-day`, {date: date}).subscribe(res => {
        resolve(res);
      });
    })
  }
}
