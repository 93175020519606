import { Injectable } from '@angular/core';
import { TokenService } from "../curd/token.service";
import { ApiService } from "../../../services/misc/api.service";
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ApiDataFetchService {

  constructor(
    private token: TokenService,
    private api: ApiService,
    private toast: ToastService
  ) {
  }

  getToken() {
    return new Promise((resolve, reject) => {
      this.token.list().then(tokens => {
        //@ts-ignore
        if (tokens.length != 0) {
          const token = tokens[0];
          const today = new Date();

          const date_created = tokens[0].updated_at;
          const expired_at = (new Date(date_created)).getTime() + (3500 * 1000);


          if (today > new Date(expired_at)) {
            this.api.refreshToken(token['refresh_token']).then(access_token => {
              resolve(access_token);
            });
          } else {
            console.log(tokens[0].access_token);
            console.log('not-expired')
            resolve(tokens[0].access_token);
          }
        }else {
          this.toast.showError('Access Token not found. Login To Old System')
        }
      })
    })
  }

}
