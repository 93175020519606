import { Component, OnInit } from '@angular/core';
import { DoctorService } from 'src/app/core/services/curd/doctor.service';
import {AuthService} from '../../../core/services/misc/auth.service';

@Component({
  selector: 'app-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrls: ['./off-canvas.component.scss']
})
export class OffCanvasComponent implements OnInit {

  doc_registered = true;

  constructor(
    private doctor: DoctorService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.loadDoctorInfo()
  }

  getLoggedUserType(){
    return this.auth.getUserType();
  }

  loadDoctorInfo() {
    this.doctor.list().then(res => {
      // @ts-ignore
      if (res) {
        this.doc_registered = false
      }
    })
  }
}
