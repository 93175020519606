import {Injectable} from '@angular/core';
import {HotToastService} from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toast: HotToastService
  ) {
  }

  inputError() {
    this.toast.error('Invalid Input. Try Again');
  }

  showError(message) {
    this.toast.error(message);
  }

  success() {
    this.toast.success('Data Saved Successfully');
  }

  successText(text) {
    this.toast.success(text);
  }
  
  observe(loading_message: string, success_message: string, error_message: string) {
    return this.toast.observe(
      {
        loading: loading_message,
        success: success_message,
        error: error_message,
      }
    )
  }
}
