<div class="offcanvas offcanvas-end" tabindex="-1" id="Appearance" aria-labelledby="AppearanceLabel">
  <div class="offcanvas-header border-bottom">
    <h5 class="m-0 font-14" id="AppearanceLabel">Main Menu</h5>
    <button type="button" class="btn-close text-reset p-0 m-0 align-self-center" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <!--    Start menu Item-->
    <!-- <h6>DENTIST</h6> -->
    <!-- <div class="p-2 text-start mt-3">
      <div class="list-group">

        <button type="button" class="list-group-item list-group-item-action" routerLink="form/dentist">Dentist Form</button>
      </div>
    </div> -->
    <h6>APPOINTMENTS</h6>
    <div class="p-2 text-start mt-3">
      <div class="list-group">
        <!--        <button type="button" class="list-group-item list-group-item-action" routerLink="appointments/create">Create Appointment</button>-->
        <button type="button" class="list-group-item list-group-item-action" routerLink="appointments/list">Appointments
          List</button>
        <button type="button" class="list-group-item list-group-item-action" routerLink="appointments/list/all">All
          Appointments</button>
      </div>
    </div>
    <h6 *ngIf="getLoggedUserType() == 'Consultant'">PATIENTS</h6>
    <div class="p-2 text-start mt-3" *ngIf="getLoggedUserType() == 'Consultant'">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="patient/registration">Create Patients</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="patients/list">All
          Patients</button>
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="patients/list">All </button> -->
      </div>
    </div>
    <!--end /div-->
    <h6>CHANNELING SLOTS</h6>
    <div class="p-2 text-start mt-3">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="channeling-slots/create">Create Channeling Slot</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="channeling-slots/list">All
          Channeling Slots</button>
      </div>
    </div>
<!--    <h6>DENTIST</h6>-->
<!--    <div class="p-2 text-start mt-3">-->
<!--      <div class="list-group">-->
<!--        &lt;!&ndash; <button type="button" class="list-group-item list-group-item-action" routerLink="channeling-slots/create">Create Channeling Slot</button> &ndash;&gt;-->
<!--        <button type="button" class="list-group-item list-group-item-action" routerLink="form/dentist">Dentist-->
<!--          Form</button>-->
<!--      </div>-->
<!--    </div>-->
    <h6 *ngIf="doc_registered==true">DOCTOR</h6>
    <div class="p-2 text-start mt-3" *ngIf="doc_registered==true">
      <div class="list-group">
        <button type="button" class="list-group-item list-group-item-action" routerLink="doctor/create">Create
          Doctor</button>
      </div>
    </div>
    <!--    <h6>DOCTOR</h6>-->
    <!--    <div class="p-2 text-start mt-3">-->
    <!--      <div class="list-group">-->
    <!--        <button type="button" class="list-group-item list-group-item-action" routerLink="doctor/create">Create Doctor</button>-->
    <!--        &lt;!&ndash; <button type="button" class="list-group-item list-group-item-action" routerLink="doctor/edit">Edit Doctor</button> &ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>&lt;!&ndash;end /div&ndash;&gt;-->
    <h6 *ngIf="getLoggedUserType() == 'Consultant'"> CHANNELING CENTER</h6>
    <div class="p-2 text-start mt-3" *ngIf="getLoggedUserType() == 'Consultant'">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="channeling-center/create">Create Channeling Center</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="channeling-center/list">All
          Channeling Centers</button>
      </div>
    </div>
    <!--end /div-->
    <h6 *ngIf="getLoggedUserType() == 'Consultant'"> DYNAMIC DATA MANAGEMENT </h6>
    <div class="p-2 text-start mt-3" *ngIf="getLoggedUserType() == 'Consultant'">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="form-dynamic-data-management/create">Create Dynamic Data Management</button> -->
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="form-dynamic-data-management/list">All Dynamic Data Management</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="dynamic-data-create"> Dynamic
          Data </button>
      </div>
    </div>
    <!--end /div-->
    <h6 *ngIf="getLoggedUserType() == 'Consultant'"> REFER TO DOCTORS </h6>
    <div class="p-2 text-start mt-3" *ngIf="getLoggedUserType() == 'Consultant'">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="refer-to-doctors/create">Create Refer To doctor </button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="refer-to-doctors/list">All
          Refer To doctors </button>
      </div>
    </div>
    <!--end /div-->
    <h6>MEDICINE MANAGEMENT</h6>
    <div class="p-2 text-start mt-3">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="brand-management/create">Create Brand Management</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="brand-management/list">All
          Brands</button>
      </div>
    </div>
    <!--end /div-->
    <div class="p-2 text-start mt-3">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="medicine-management/create">Create Medicine Management</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="medicine-management/list"> All
          Medicines </button>
      </div>
    </div>
    <!--end /div-->
    <div class="p-2 text-start mt-3">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="dosage-management/create">Create Dosage Management</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="dosage-management/list"> All
          Dosage Managements</button>
      </div>
    </div>
    <!--end /div-->
    <div class="p-2 text-start mt-3">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="frequency-management/create">Create Frequency Management</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="frequency-management/list">All
          Frequency Managements</button>
      </div>
    </div>
    <!--end /div-->
    <div class="p-2 text-start mt-3">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="schedule-management/create">Create Schedule Management</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="schedule-management/list">All
          Schedule Managements</button>
      </div>
    </div>
    <!--end /div-->
    <h6 *ngIf="getLoggedUserType() == 'Consultant'">USER MANAGEMENT</h6>
    <!--    <div class="p-2 text-start mt-3">-->
    <!--      <div class="list-group">-->
    <!--        <button type="button" class="list-group-item list-group-item-action" routerLink="role-management/create">Create Role Management</button>-->
    <!--        <button type="button" class="list-group-item list-group-item-action" routerLink="role-management/list"> Role management List</button>-->
    <!--      </div>-->
    <!--    </div>&lt;!&ndash;end /div&ndash;&gt;-->
    <div class="p-2 text-start mt-3" *ngIf="getLoggedUserType() == 'Consultant'">
      <div class="list-group">
        <!-- <button type="button" class="list-group-item list-group-item-action" routerLink="user-management/create">Create User Management</button> -->
        <button type="button" class="list-group-item list-group-item-action" routerLink="user-management/list"> User
          management List</button>
      </div>
    </div>
    <h6>LETTER MANAGEMENT</h6>
    <div class="p-2 text-start mt-3">
      <div class="list-group">
        <button type="button" class="list-group-item list-group-item-action" routerLink="letter/list">
          Reffer Letters
        </button>
      </div>
    </div>
    <!--end /div-->
  </div>
</div>
