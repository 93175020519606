import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppointmentsService } from '../../../core/services/curd/appointments.service';
import { TimeService } from '../../../core/services/curd/time.service';
import { ChannelingCenterService } from '../../../core/services/curd/channeling-center.service';
import { PatientsService } from '../../../core/services/curd/patients.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-appointment-create',
  templateUrl: './appointment-create.component.html',
  styleUrls: ['./appointment-create.component.scss']
})

export class AppointmentCreateComponent implements OnInit {

  channeling_centers: any = [];
  slots: any = [];
  patient_name: any;
  patient_arr: any;
  center_arr: any;
  center_name: any;
  patients_list: any = [];

  constructor(
    private matDialogRef: MatDialogRef<any>, 
    private appointment: AppointmentsService,
    private fromBuilder: FormBuilder,
    private channelingCenterService: ChannelingCenterService,
    private patientsService: PatientsService,
    private timeService: TimeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadChannelingCenters();
    this.loadSlots();
    this.loadPatinas();
  }

  appointments_form = this.fromBuilder.group({
    channeling_center: new FormControl(''),
    slot: new FormControl(''),
    patient: new FormControl(''),
  });

  createAppointment() {
    const date = new Date()
    var year = (new Date()).getFullYear();
    var ab: number = year
    var str: string = ab + '';
    var modified_year = str.substring(2,5);
    var month = (new Date()).getMonth();
    var new_month=month+1
    var day = (new Date()).getDate();
    var hours = (new Date()).getHours();
    var minutes = (new Date()).getMinutes();
    var seconds = (new Date()).getSeconds();
    const date_id = `${modified_year}${new_month}${day}${hours}${minutes}${seconds}`;
    const input_data = 
    {
      channeling_center_name: this.center_name,
      channeling_center_id: this.appointments_form.value.channeling_center,
      patient_id: this.appointments_form.value.patient,
      patient_name: this.patient_name,
      slot_id: this.appointments_form.value.slot,
      visit_id:date_id
    }
    let splitted = input_data.slot_id.split('##');
    input_data.slot_id = splitted[0];
    // @ts-ignore
    input_data.slot_date = splitted[1];
    this.appointment.create(input_data).then(res => {
      this.matDialogRef.close();
    })
  }

  getPatientName(value) {
    this.patient_arr = this.patients_list.filter(obj => obj._doc._id == value.target.value);
    this.patient_name = this.patient_arr[0]._doc.name
  }

  getCenterName(value) {
    this.center_arr = this.channeling_centers.filter(obj => obj._doc._id == value.target.value);
    this.center_name = this.center_arr[0]._doc.channeling_center_name
  }

  loadChannelingCenters() {
    this.channelingCenterService.list().then(res => {
      this.channeling_centers = res;
    })
  }

  loadPatinas() {
    this.patientsService.list().then(res => {
      this.patients_list = res;
    })
  }

  loadSlots() {
    this.timeService.list().then(res => {
      this.slots = res;
    })
  }

  cancel() {
    this.matDialogRef.close();
    this.router.navigate(['appointments/list']);
  }

  save() {
    this.matDialogRef.close();
  }
}
