<div class="container-md">
  <div class="row vh-100 d-flex justify-content-center">
    <div class="col-12 align-self-center">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 mx-auto">
            <div class="card">
              <div class="card-body p-0 auth-header-box">
                <div class="text-center p-3">
                  <a href="index.html" class="logo logo-admin">
                    <img src="assets/images/logomed.png" height="50" alt="logo" class="auth-logo">
                  </a>
                  <h4 class="mt-3 mb-1 fw-semibold text-red font-18">Entered OTP doesn't match.</h4>

                </div>
              </div>
              <div class="card-body pt-0">


                <div class="m-3 text-center text-muted">
                  <p class="mb-0">
                    <a class="text-primary ms-2" (click)="login()">Log in</a>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
