import { Component, Inject, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { RefferLetterService } from '../../../core/services/curd/reffer-letter.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrls: ['./referral-modal.component.scss']
})
export class ReferralModalComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '15rem',
    maxHeight: 'auto',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
    // toolbarPosition: 'top',
    outline: true,
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
    ],
    defaultFontName: 'Arial',
    defaultFontSize: '5',
    // showToolbar: false,
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private RefferLetterService: RefferLetterService,
    private fromBuilder: FormBuilder,
    ){ }

    template_id: any;
    description: any;
    all_data: any;
    letter_form = this.fromBuilder.group({
      template_body: new FormControl('', Validators.required),
    });

  ngOnInit(): void {
    this.all_data=this.data;
    this.template_id=this.data.template_id;
    console.log(this.all_data);
    this.getData();
  }

  getData() {
    this.RefferLetterService.show(this.template_id).then(res => {
      // @ts-ignore
      this.description=res.template_body;
      console.log(this.description)
      if(this.description.toString().includes('{medapp_id}')){

        this.description= this.description.toString().replaceAll("{medapp_id}", this.all_data.medapp_id);
      }
      if(this.description.toString().includes('{patient_name}')){

        this.description= this.description.toString().replaceAll("{patient_name}", this.all_data.patient_name);
      }
      if(this.description.toString().includes('{patient_dob}')){

        this.description= this.description.toString().replaceAll("{patient_dob}", this.all_data.patient_dob);
      }
      if(this.description.toString().includes('{age}')){

        this.description= this.description.toString().replaceAll("{age}", this.all_data.age);
      }
      if(this.description.toString().includes('{patient_gender}')){

        this.description= this.description.toString().replaceAll("{patient_gender}", this.all_data.patient_gender);
      }
      if(this.description.toString().includes('{patient_nic}')){

        this.description= this.description.toString().replaceAll("{patient_nic}", this.all_data.patient_nic);
      }
      if(this.description.toString().includes('{patient_address}')){

        this.description= this.description.toString().replaceAll("{patient_address}", this.all_data.patient_address);
      }
      if(this.description.toString().includes('{patient_phone}')){

        this.description= this.description.toString().replaceAll("{patient_phone}", this.all_data.patient_phone);
      }
      if(this.description.toString().includes('{reffered_by}')){

        this.description= this.description.toString().replaceAll("{reffered_by}", this.all_data.reffered_by);
      }
      if(this.description.toString().includes('{primary_complaints}')){

        this.description= this.description.toString().replaceAll("{primary_complaints}", this.all_data.primary_complaints);
      }
      if(this.description.toString().includes('{secondary_complaints}')){

        this.description= this.description.toString().replaceAll("{secondary_complaints}", this.all_data.secondary_complaints);
      }
      if(this.description.toString().includes('{tertiary_complains}')){

        this.description= this.description.toString().replaceAll("{tertiary_complains}", this.all_data.tertiary_complains);
      }
      if(this.description.toString().includes('{cardiac_conditions}')){

        this.description= this.description.toString().replaceAll("{cardiac_conditions}", this.all_data.cardiac_conditions);
      }
      if(this.description.toString().includes('{past_pci}')){

        this.description= this.description.toString().replaceAll("{past_pci}", this.all_data.past_pci);
      }
      if(this.description.toString().includes('{past_cabg}')){

        this.description= this.description.toString().replaceAll("{past_cabg}", this.all_data.past_cabg);
      }
      if(this.description.toString().includes('{past_cardiac_surgeries}')){

        this.description= this.description.toString().replaceAll("{past_cardiac_surgeries}", this.all_data.past_cardiac_surgeries);
      }
      if(this.description.toString().includes('{past_structural_interventions}')){

        this.description= this.description.toString().replaceAll("{past_structural_interventions}", this.all_data.past_structural_interventions);
      }
      if(this.description.toString().includes('{past_cardiac_history_other}')){

        this.description= this.description.toString().replaceAll("{past_cardiac_history_other}", this.all_data.past_cardiac_history_other);
      }
      if(this.description.toString().includes('{past_medical_history}')){

        this.description= this.description.toString().replaceAll("{past_medical_history}", this.all_data.past_medical_history);
      }
      if(this.description.toString().includes('{past_surgical_history}')){

        this.description= this.description.toString().replaceAll("{past_surgical_history}", this.all_data.past_surgical_history);
      }
      if(this.description.toString().includes('{current_medications}')){

        this.description= this.description.toString().replaceAll("{current_medications}", this.all_data.current_medications);
      }
      if(this.description.toString().includes('{previous_cardiac_investigations}')){

        this.description= this.description.toString().replaceAll("{previous_cardiac_investigations}", this.all_data.previous_cardiac_investigations);
      }
      if(this.description.toString().includes('{previous_biochemical_invesitigations}')){

        this.description= this.description.toString().replaceAll("{previous_biochemical_invesitigations}", this.all_data.previous_biochemical_invesitigations);
      }
      if(this.description.toString().includes('{previous_radiological_investigations}')){

        this.description= this.description.toString().replaceAll("{previous_radiological_investigations}", this.all_data.previous_radiological_investigations);
      }
      if(this.description.toString().includes('{family_history}')){

        this.description= this.description.toString().replaceAll("{family_history}", this.all_data.family_history);
      }
      if(this.description.toString().includes('{social_history}')){

        this.description= this.description.toString().replaceAll("{social_history}", this.all_data.social_history);
      }
      if(this.description.toString().includes('{allergies}')){

        this.description= this.description.toString().replaceAll("{allergies}", this.all_data.allergies);
      }
      if(this.description.toString().includes('{occupation}')){

        this.description= this.description.toString().replaceAll("{occupation}", this.all_data.occupation);
      }
      if(this.description.toString().includes('{health_data_examination}')){

        this.description= this.description.toString().replaceAll("{health_data_examination}", this.all_data.health_data_examination);
      }
      if(this.description.toString().includes('{asculation}')){

        this.description= this.description.toString().replaceAll("{asculation}", this.all_data.asculation);
      }
      if(this.description.toString().includes('{lungs}')){

        this.description= this.description.toString().replaceAll("{lungs}", this.all_data.lungs);
      }
      if(this.description.toString().includes('{clinical_impressions}')){

        this.description= this.description.toString().replaceAll("{clinical_impressions}", this.all_data.clinical_impressions);
      }
      if(this.description.toString().includes('{differential_diagnosis}')){

        this.description= this.description.toString().replaceAll("{differential_diagnosis}", this.all_data.differential_diagnosis);
      }
      if(this.description.toString().includes('{prescription}')){

        this.description= this.description.toString().replaceAll("{prescription}", this.all_data.prescription);
      }
      if(this.description.toString().includes('{todo_cardiac_investigations}')){

        this.description= this.description.toString().replaceAll("{todo_cardiac_investigations}", this.all_data.todo_cardiac_investigations);
      }
      if(this.description.toString().includes('{todo_biochemical_invesitigations}')){

        this.description= this.description.toString().replaceAll("{todo_biochemical_invesitigations}", this.all_data.todo_biochemical_invesitigations);
      }
      if(this.description.toString().includes('{todo_radiological_investigations}')){

        this.description= this.description.toString().replaceAll("{todo_radiological_investigations}", this.all_data.todo_radiological_investigations);
      }
      if(this.description.toString().includes('{further_plan}')){

        this.description= this.description.toString().replaceAll("{further_plan}", this.all_data.further_plan);
      }
      if(this.description.toString().includes('{reffered_to}')){

        this.description= this.description.toString().replaceAll("{reffered_to}", this.all_data.reffered_to);
      }

      this.letter_form.patchValue({

        template_body: this.description,

      })
    })
  }

  print(){
    const input_data = this.letter_form.value;
    // for A4
  //   var pdf = new jsPDF('p','pt','a4');

  // pdf.html('<div style="display: inline-block;width: 500px;margin: 20px;padding: 20px">'+input_data.template_body+'</div>', {
  //   callback: function (doc) {
  //   // @ts-ignore
  //   window.open(doc.output('bloburl'));
  //   }
  // });

  //for A5
  var pdf = new jsPDF('p','pt','a5');

  pdf.html('<div style="display: inline-block;width: 380px;margin: 20px;padding: 20px">'+input_data.template_body+'</div>', {
    callback: function (doc) {
    // @ts-ignore
    window.open(doc.output('bloburl'));
    }
  });



}


}
