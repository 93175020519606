<div class="d-flex justify-content-between">
  <div>
      <h2 mat-dialog-title>Referral Letter</h2>
  </div>
  <div class="control-button">
      <button mat-button mat-dialog-close type="button" class="btn btn-sm  btn-outline-primary">
          <i class="ti ti-circle-x"></i>
      </button>
      <button type="button" class="btn btn-sm  btn-outline-primary">
          <i class="ti ti-printer" (click)="print()"></i>
      </button>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="letter_form">
      <div class="row">
          <div class="col-md-12">
              <div class="form-group row mb-2">
                  <label>Template Body</label>
                  <div class="col-md-12">
                    <angular-editor
                    formControlName="template_body"
                    [config]="editorConfig"
                    rows="5"
                  ></angular-editor>
                  </div>
              </div>
          </div>


      </div>
  </form>
</mat-dialog-content>
