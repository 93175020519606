<div class="container-md">
  <div class="row vh-100 d-flex justify-content-center">
    <div class="col-12 align-self-center">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 mx-auto">
            <div class="card">
              <div class="card-body p-0 auth-header-box">
                <div class="text-center p-3">
                  <a href="index.html" class="logo logo-admin">
                    <img src="assets/images/logomed.png" height="50" alt="logo" class="auth-logo">
                  </a>
                  <h4 class="mt-3 mb-1 fw-semibold text-white font-18">Enter OTP</h4>
                  <p class="text-muted  mb-0">Please enter OTP</p>
                </div>
              </div>
              <div class="card-body pt-0">
                <form class="my-4" [formGroup]="otp_check_form">
                  <div class="form-group mb-2">
                    <label class="form-label">OTP</label>
                    <input type="number" class="form-control" name="otp" formControlName="otp"
                      placeholder="Enter OTP">
                  </div>
                  <div class="form-group mb-0 row">
                    <div class="col-12">
                      <div class="d-grid mt-3">
                        <button class="btn btn-primary" (click)="check()" type="button">Check OTP
                          <i class="fas fa-sign-in-alt ms-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="m-3 text-center text-muted">
                  <p class="mb-0">
                    <a class="text-primary ms-2" (click)="login()">Log in</a>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
