import {Injectable} from '@angular/core';
import {ToastService} from './toast.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: any;

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private router: Router
  ) {
  }

  login(input) {
    return new Promise(resolve => {
      this.http.post(`${environment.auth_url}/login`, input).subscribe(res => {
        if (res['access_token']) {
          // @ts-ignore
          localStorage.setItem('sustik-med', JSON.stringify(res));
          this.token = res['access_token'];
        }
        resolve(res);
      });
    });
  }

  getToken(): string {
    if (!this.token) {
      let str = localStorage.getItem('sustik-med');
      if (str) {
        str = JSON.parse(str);
        this.token = str['access_token'];
      }
    }
    return this.token;
  }

  getUser() {
    let str = localStorage.getItem('sustik-med');
    if (str) {
      str = JSON.parse(str);
      return JSON.parse(str['payload']);
    }
  }

  isLogged() {
    let user = this.getUser();
    if (user) {
      return user.exp > Date.now() / 1000;
    }
    return false;
  }

  getUserType(){
    return this.getUser().type;
  }

  getAllowedLocations(){
    return this.getUser().allowed_locations;
  }

  authorize(password) {
    return new Promise(resolve => {
      this.http.post(`${environment.auth_url}/authorize/create`, {password: password}).subscribe(res => {
        resolve(res);
      });
    });
  }

  public logout(): void {
    this.token = '';
    window.localStorage.removeItem('sustik-med');
    this.router.navigateByUrl('/');
  }

}
