import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiDataFetchService } from "../../core/services/misc/api-data-fetch.service";
import { PatientsService } from '../../core/services/curd/patients.service';
import { ChannelingCenterService } from '../../core/services/curd/channeling-center.service';
import { TimeService } from '../../core/services/curd/time.service';
import { AppointmentsService } from '../../core/services/curd/appointments.service';
import { ApiService } from "../../services/misc/api.service";
import { PatientDataService } from '../../core/services/curd/patient-data.service';
import { ToastService } from '../../core/services/misc/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiOldDataService {

  api_url = environment.api_url_old_system;
  api_handler = environment.api_handler;

  constructor(
    private http: HttpClient,
    private api_fetch: ApiDataFetchService,
    private patients: PatientsService,
    private channelingCenterService: ChannelingCenterService,
    private timeService: TimeService,
    private appointmentsService: AppointmentsService,
    private api: ApiService,
    private patientData: PatientDataService,
    private toast: ToastService
  ) { }

  apiGetPatientInfoByMedicalId(medical_id) {
    console.log(medical_id);
    console.log(this.api_url);

    this.api_fetch.getToken().then(access_token => {
      console.log(access_token);

      const cf_object = {
        "url": `${this.api_url}/api/get-patient-info`,
        "token": access_token,
        "data": { medicalId: medical_id }
      };

      this.http.post(this.api_handler, cf_object).subscribe(async patient => {

        let patient_object = {

          patient_id: patient['medicalId'],
          name: patient['name'],
          nic: patient['nic'],
          dob: '2021-01-01',
          gender: patient['gender'],
          address: patient['address'],
          city: patient['city'],
          mobile: '-',
          country: 'Sri Lanka',
          synced: false
        }
        console.log(patient_object);
        await this.patients.createWithOutToast(patient_object).then((created_patient) => {
          //@ts-ignore
          console.log(created_patient);
          const cf_object_visit = {
            "url": `${this.api_url}/api/channeling/appointment/history`,
            "token": access_token,
            "data": { "medicalId": medical_id }
          };

          this.http.post(this.api_handler, cf_object_visit).subscribe(async bookings => {
            //@ts-ignore
            for (let booking of bookings) {
              console.log(booking);
              const locate = { channeling_center_name: booking.location }
              await this.channelingCenterService.getCenterByName(locate).then(async (centers) => {
                const slot_data = {
                  channeling_center_name: booking.location,
                  date: booking.date
                }
                await this.timeService.getSlotByDateName(slot_data).then(async (slots) => {
                  console.log(slots);
                  //@ts-ignore
                  let first_center = centers[0]._id;

                  let booking_object = {
                    original_id: booking.id,
                    visit_id: booking.visits + "*",
                    channeling_center_name: booking.location,
                    channeling_center_id: first_center,
                    //@ts-ignore
                    patient_id: created_patient.data,
                    patient_name: patient['name'],
                    //@ts-ignore
                    slot_id: slots[0]._id,
                    //@ts-ignore
                    slot_date: slots[0].date,
                    type: 'visit',
                    booking_name: '',
                    booking_phone: '',
                    token: booking.number,
                    medical_id: booking.medicalId,
                    synced: false
                  }

                  console.log(booking_object);
                  await this.appointmentsService.createWithOutToast(booking_object).then(async res => {
                    console.log(res)
                    const cf_object_visit = {
                      "url": `${this.api_url}/api/patient-history/appointment/${booking.id}/form`,
                      "token": access_token,
                      "data": { "medicalId": medical_id }
                    };
                    console.log(cf_object);
                    this.http.post(this.api_handler, cf_object_visit).subscribe(async data => {

                      console.log(data);
                      data = data['data']

                      let patient_data = {
                        visit_id: res['data'],
                        //@ts-ignore
                        patient_id: created_patient.data,
                        patient_data_form: {
                          patient_name: booking.name,
                          visit_id: booking.visitId,
                          med_id: booking.medicalId,
                          dob: data['dateOfBirth'],
                          age: '',
                          gender: data['gender'],
                          nic: data['nic'],
                        },
                        contact_info: {
                          address: data['address'],
                          email: data['mobile']
                        },
                        referred_by: data['referredBy'],
                        current_complaints: {
                          primary_complaints: this.api.complaints(),
                          secondary_complaints: this.api.complaints(),
                          tertiary_complaints: this.api.complaints(),
                          complaint_other: data['currentComplaints']
                        },
                        examinations: {
                          examinations: {
                            height: data['height'],
                            weight: data['weight'],
                            bmi: data['bmi'],
                            pulse_rate: data['pulseRate'],
                            bp: data['bp'],
                            pulse: 'Regular'
                          },
                          auscultation_data: this.api.asculation(),
                          lungs_first: this.api.lungsFirst(),
                          lungs_second: this.api.lungsSecond(),
                          examination_other: data['examingdationFindings']
                        },
                        previous_history: {
                          family_history: data['familyHistory'],
                          cardiac_risk_Factors: this.api.CardiacRiskFactor(data),
                          other_med_conditions: this.api.otherMedicalConditions(data),
                          cardiac_investigations: this.api.cardiacInvestigations(),
                          radiological_investigations: this.api.radiological_investigations(),
                          // biochemical_investigations: this.api.biochemical_investigations(data),
                          clinical_impressions: '',
                          differential_diagnosis: data['currentDiagnosis']
                        },
                        past_surgical_history: {
                          past_pci: this.api.pastPci(data),
                          past_cabg: this.api.pastCabg(data),
                          past_surgeries: this.api.past_surgeries(data),
                          past_structures: this.api.past_structures(),
                          past_surgical_other: data['extraPastSurgeries']
                        },
                        current_medications: this.api.medications(data['currentMeds']),
                        prescriptions: this.api.medications(data['prescriptionMeds']),
                        social_history: {
                          allergies: data['alergies'],
                          social_history: [
                            {
                              selected: data['smoking'],
                              value: 'Smoking',
                              duration: '',
                              remarks: 'minutes',
                              is_default: true
                            },
                            {
                              selected: data['alcoholic'],
                              value: 'Alcohol',
                              duration: '',
                              remarks: 'minutes',
                              is_default: true
                            },
                            {
                              selected: false,
                              value: 'Ex-Smoking',
                              duration: '',
                              remarks: 'minutes',
                              is_default: true
                            },
                            {
                              selected: false,
                              value: 'Ex-Alcohol',
                              duration: '',
                              remarks: 'minutes',
                              is_default: true
                            },
                          ]
                        },
                        Investigations: {
                          investigations_cardiac_investigations: this.api.cardiacInvestToDone(data),
                          radiological_investigations: this.api.radiological_investigations_to_done(data),
                          biochemical_investigations: this.api.bio_investigations_to_done(data)
                        },
                        plan_of_management: {
                          plan_of_management: this.api.plan_of_management(),
                          plan_of_manage_description: data['planOfManagement']
                        },
                        refer_to: data['referredTo'],
                        synced: false
                      }

                      await this.patientData.createWithOutToast(patient_data).then(async patient_data => {
                        this.toast.successText('Data Imported Successfully. Please search patient again and create appointment');
                      }).catch(err => console.log(err))
                    }, error => {

                    });


                  }).catch(error => {
                    console.log(error);

                  })
                });
              });
            }
          }, error => {
            console.log(error);

          });
        });

      }, error => {
        console.log(error);
        this.toast.showError('Patient not Found');

      });

    });
  }

}
