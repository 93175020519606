import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from '../../../core/services/curd/user.service';
import { Router } from "@angular/router";
import {ToastService} from '../../../core/services/misc/toast.service';

@Component({
  selector: 'app-reset-email',
  templateUrl: './reset-email.component.html',
  styleUrls: ['./reset-email.component.scss']
})
export class ResetEmailComponent implements OnInit {

  email_check_form = new FormGroup({
    email: new FormControl('', Validators.required),

  })
  find_user: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
  }


  check(){
    const input_data = this.email_check_form.value;
    console.log(input_data.email);
    if(input_data.email){

    this.userService.checkUserEmail({email: input_data.email}).then(res => {
      this.find_user=res;
      console.log(this.find_user);
      if(this.find_user){
        let otp= Math.floor(100000 + Math.random() * 900000);
        const details = {
          _id: this.find_user._id,
          input_data: { otp: otp },
        };
        const data = {
          mail: input_data.email,
          otp: otp ,
        };
 
          this.userService.updateWithoutToast(details).then(() => {

           this.router.navigate(['check/otp/match', this.find_user._id]);
              //  this.userService.sendResetOTP(data).then(() => {
              //     this.toast.showError('Email Sent Successfully..');
              //   })
           })
      }else{
        this.toast.showError('Entered email not found');
      }

    });

  }
  }

  login() {
    this.router.navigate(['login']);
  }

}
