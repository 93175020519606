import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from '../../../core/services/curd/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService} from '../../../core/services/misc/toast.service';

@Component({
  selector: 'app-check-otp',
  templateUrl: './check-otp.component.html',
  styleUrls: ['./check-otp.component.scss']
})
export class CheckOtpComponent implements OnInit {

  otp_check_form = new FormGroup({
    otp: new FormControl('', Validators.required),

  })
  find_user: any;
  user_id: any;
  user_otp: any;

   constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
  ) { }


  ngOnInit(): void {
    this.user_id = this.route.snapshot.paramMap.get('id');
    console.log(this.user_id);
    this.getuserData();
  }

  getuserData(){
    this.userService.authShow(this.user_id).then(res => {
      // @ts-ignore
      this.user_otp=res.otp;
      console.log(this.user_otp);
    })
  }

  login() {
    this.router.navigate(['login']);
  }

  check(){
    const input_data = this.otp_check_form.value;

    if(input_data.otp==this.user_otp){
      this.router.navigate(['password/reset',this.user_id]);
    }else{
      this.toast.showError('Entered OTP not match');
      this.router.navigate(['otp/invalidate']);
    }
  }

}
